.gripStudio-wrapper1 {
  display: flex;
  flex-direction: column !important;
  background-color: hsl(0, 0%, 5%);
  padding-block-start: 6rem;
  padding-block-end: 3rem;
  width: 100vw;
}
.gripStudio-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-wrapper {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-block-start: 1rem;
}
.content-wrapper > img {
  width: 50%;
  position: absolute;
}

.step3-image {
  width: 40% !important;
}
.content {
  text-align: left;
  max-width: 60%;
  position: absolute;
  top: 36px;
  left: 9rem;
}
.gripStudio-main {
  display: flex;
  flex-direction: column !important;
}
.heading {
  color: #f7f7f7 !important;
  text-align: center !important;
  font-family: Causten;
  font-size: min(4rem, 10vw) !important;

  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100% !important;
  letter-spacing: -1.32px !important;
}
.heading-para {
  color: #848484;
  font-size: 1.125 !important;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block-end: 5rem !important;
  padding-inline: 30rem;
  font-family: "Causten-400" !important;
  margin-bottom: 75px !important;
}
.title {
  color: #f7f7f7 !important;
  font-size: 2.2rem !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  font-family: "Causten-600" !important;
}
.title-para {
  color: #9c9c9c;
  font-family: "Causten-400" !important;
  font-size: 1, 2rem;
  font-style: normal;
  line-height: 140%;
  max-width: 100%;
}
.break-line > img {
  margin-top: 4rem;
  margin-bottom: -3rem;
}
@media screen and (max-width: 1366px) {
  .break-line > img {
    margin-top: 4rem;
    margin-bottom: -3rem;
  }
  .content {
    text-align: left;
    max-width: 100%;
    position: absolute;
    left: 4rem;
  }
  .title-para {
    max-width: 90%;
  }
  .heading-para {
    padding-inline: 14rem !important;
    font-size: 1.125rem !important;
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 1024px) {
  .gripStudio-wrapper1 {
    display: flex;
    flex-direction: column !important;
    background-color: hsl(0, 0%, 5%);
  }
  .gripStudio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .content-wrapper > img {
    width: 40%;
    position: absolute;
  }

  .step3-image {
    width: 40% !important;
  }
  .content {
    text-align: left;
    max-width: 100%;
    position: absolute;
    /* top: 36px; */
    left: 4rem;
  }
  .gripStudio-main {
    display: flex;
    flex-direction: column !important;
  }
  .heading {
    color: #f7f7f7 !important;
    text-align: center !important;
    font-family: Causten;
    font-size: 3rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    letter-spacing: -1.32px !important;
  }
  .heading-para {
    color: #848484;
    font-size: 1.125rem !important;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 8rem !important;
    padding-block-end: 3rem !important;
    font-family: "Causten-400" !important;
  }
  .title {
    color: #f7f7f7 !important;
    font-size: 2.25rem !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    font-family: "Causten-600" !important;
  }
  .title-para {
    color: #9c9c9c;
    font-family: Causten;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 90%;
  }
  .break-line > img {
    margin-top: 4rem;
    margin-bottom: -1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .gripStudio-wrapper1 {
    display: flex;
    flex-direction: column !important;
    background-color: hsl(0, 0%, 5%);
    padding-block-start: 3rem;
    padding-block-end: 13rem;
  }
  .gripStudio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .content-wrapper > img {
    width: 40%;
    position: absolute;
  }

  .step3-image {
    width: 40% !important;
  }
  .content {
    text-align: left;
    max-width: 100%;
    position: absolute;
    left: 2rem;
  }
  .gripStudio-main {
    display: flex;
    flex-direction: column !important;
  }
  .heading {
    color: #f7f7f7 !important;
    text-align: center !important;
    font-family: Causten;
    font-size: 2rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    letter-spacing: -1.32px !important;
  }
  .heading-para {
    color: #848484;
    font-size: 1.125rem !important;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 8rem !important;
    padding-block-end: 3rem !important;
    margin-bottom:35px !important;
  }
  .title {
    color: #f7f7f7 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 110% !important;
  }
  .title-para {
    color: #9c9c9c;
    font-family: Causten;
    font-size: 0.8rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 100%;
  }
  .break-line > img {
    margin-top: 4rem;
    margin-bottom: -1rem;
  }
}

@media screen and (max-width: 767px) {
  .break-line > img {
    margin-top: 1rem;
  }
  .content-wrapper {
    padding-block-start: 2rem;
    position: relative;
  }
  .gripStudio-wrapper {
    display: flex !important;
    flex-direction: column !important;
    gap: 2rem;

    width: 100%;
  }
  .gripStudio-wrapper1 {
    padding: 0;
    padding-block: 3rem;
    padding-block-end: unset;
  }
  .step3-image {
    width: 80%;
  }
  .heading {
    font-size: 2rem !important;
  }
  .heading-para {
    font-size: 1.125rem !important ;
    max-width: 100%;
    padding-inline: 1rem !important;
    margin-bottom: 25px;
  }
  .title {
    font-size: 1.5rem !important;
  }

  .title-para {
    font-size: 1.125rem !important;
  }
  .content-wrapper > img {
    width: 40%;
    position: absolute !important;
    top: 20px;
  }
  .content {
    text-align: center;
    max-width: 90%;
    position: unset;
    margin-top: 3.8rem;
  }
  .gripStudio-wrapper1 {
    padding-block: 3rem;
  }
}
