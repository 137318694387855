.get-early-heading {
  font-family: "Causten-700";
  line-height: 100%;
  padding-bottom: 4rem;
  font-size: 4rem;
}
.get-early-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 100vh;
  align-items: center;
}

.early-section {
  margin-left: 7rem !important;
}
.please-text {
  font-size: 1.5rem;
  color: #ffffff;
}

.form-box {
  border: 2px solid #44434f;
  width: 12rem !important;
  height: 12rem !important;
  text-align: center;
  border-radius: 20px;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  margin-left: 2rem;
  cursor: pointer;
}
.page-section {
  padding: 1rem 0 2rem 0;
}

.form-box.selected {
  border: 2px solid white;
}
.form-box.guest {
  margin-left: 2rem;
}
.form-box img {
  margin-top: 1.5rem;
}

.box-content {
  display: flex;
  padding: 1rem 0;
}

.men-box {
  margin-left: 2rem !important;
}
.slide-form {
  transition: transform 0.5s ease;
}

.slide-form.show {
  transform: translateX(0);
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  border: 1px solid #44434f;
}
.next-btn {
  width: 100%;
}
@media (max-width: 1366px) {
  .get-early-heading {
    font-size: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .get-early-section {
    min-height: 50vh;
    flex-direction: row !important;
    justify-content: space-between;
    margin-block: 4rem;
    background: black;
  }
  .get-early-heading {
    font-size: 3rem;
    font-weight: 600;
    padding-bottom: 3rem;
  }
}
@media screen and (max-width: 991.5px) {
  .get-early-section {
    min-height: 50vh;
    flex-direction: row !important;
    justify-content: space-between;
    margin-block: 4rem;
    background: black;
  }
  .get-early-heading {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 2rem;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .get-early-section {
    min-height: unset;
  }
  .vertical-box {
    width: 9rem !important;
    height: 9rem !important;
    font-size: 12px;
  }
  .early-section {
    margin-left: 0% !important;
  }
  .slide-form {
    padding: 1rem;
  }

  .get-early-heading {
    padding: 2rem 0;
  }
  .p1 {
    max-width: 90%;
    text-align: center;
    margin-inline: auto;
    padding-block-end: 1rem;
  }
  .form {
    padding-inline: 1.5rem;
  }
}
