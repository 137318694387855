.h1 {
  font-family: "Causten-700" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 4.2rem;
  color: #ffffff;
}

.p1 {
  font-family: "Causten-400" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  color: #848484;
}

.bg-1 {
  background-color: #0d0d0d;
}

.bg-2 {
  background-color: #000000;
}

.page-alignment {
  padding: 4rem 6.25rem;
  overflow: hidden;
}

.active-btn {
  background-image: linear-gradient(85.59deg, #ff8181 31.38%, #fcd59a 152.33%);
  font-family: "Causten-700" !important;
  font-size: 14px;
  line-height: 100%;
  color: #010101 !important;
}
.inactive-btn {
  font-family: "Causten-700" !important;
  font-size: 14px;
  line-height: 100%;
  color: #f7f7f7 !important;
  border-color: 1px solid #f7f7f7 !important;
}

.causten-400 {
  font-family: "Causten-400";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}

.causten-700 {
  font-family: "Causten-700";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 140%;
}

.causten-600 {
  font-family: "Causten-600";
  font-style: normal;
  font-weight: 600;
}
.Toastify__toast-container--top-center {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.Toastify__toast {
  width: 30%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .p1 {
    font-size: 1.125rem;
    text-align: center;
  }

  .page-alignment {
    padding: 2rem 0;
  }
  .Toastify__toast {
    width: 100% !important;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .Toastify__toast {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .Toastify__toast {
    width: 40%;
  }
}
.Mui-disabled .MuiInputBase-input {
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 999999s ease-in-out 0s,
    color 999999s ease-in-out 0s;
  transition-delay: background-color 999999s, color 999999s;
  -webkit-text-fill-color: #fff !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 999999s ease-in-out 0s,
    color 999999s ease-in-out 0s;
  transition-delay: background-color 999999s, color 999999s;
  -webkit-text-fill-color: #fff !important;
}
