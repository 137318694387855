.featureCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: .75rem !important;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  padding: 1rem 1.75rem;
  color: #fff;
  gap: 2rem;
  box-shadow: 0 1px 2px 0 #f0f0f044, 0 -1px 1px 0 #0008 !important;
  height: 85%;
}

.featureCard.featured {
  background: "linear-gradient(90deg, #10ADDF 0% 40%, #886CF6 100%)";
}

.featureCardContent {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3.25rem;
  max-width: 60%;
  height: 100%;
}

.featured .featureCardContent {
  max-width: 30%;
}

.featureCardTitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2 !important;
  max-width: 80%;
}

.featureCardDescription {
  font-size: 0.9rem !important;
  line-height: 1.5 !important;
  font-weight: 400;
    max-width: 90%;
}

.button {
  background: linear-gradient(60deg, #ff8181 0% 40%, #fcd59a 120%);
  color: #0f0f0f !important;
  padding-block: 0.75rem !important;
  padding-inline: 2rem !important;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600 !important;
  padding-block: 1rem !important;
  padding-inline: 3rem !important;
  font-size: 0.75rem !important;
  line-height: 1 !important;
  transition: all 0.25s ease;
}

.button:focus, .button:active {
  background: linear-gradient(60deg, #ef7171 0% 40%, #fcd59a 120%);
  color: #0f0f0f !important;
}

.featured .button {
  background: #fff;
  color: #0f0f0f;
  padding-inline: 4rem !important;
  font-weight: 600 !important;
}

.featured .button:focus, .featured .button:active, .featured .button:hover {
  background: #fff;
  color: #0f0f0f;
}

@media screen and (min-width: 320px) {
  .featureCard {
    padding: 1.5rem 1.25rem !important;
  }

  .featureCardContent {
    max-width: 100%;
  }

  .featured .featureCardContent {
    max-width: 100%;
  }

  .featureCardTitle {
    font-size: 1.25rem !important;
  }

  .featureCardDescription {
    font-size: 0.875rem !important;
  }

  .button {
    padding-block: 0.75rem !important;
    padding-inline: 2rem !important;
    font-size: 0.75rem !important;
  }

  .featured .button {
    padding-inline: 4rem !important;
  }
}

@media screen and (min-width: 375px) {
  .featureCard {
    padding: 1.5rem 1.25rem !important;
  }

  .featureCardContent {
    max-width: 100%;
  }

  .featured .featureCardContent {
    max-width: 100%;
  }

  .featureCardTitle {
    font-size: 1.25rem !important;
  }

  .featureCardDescription {
    font-size: 0.875rem !important;
  }

  .button {
    padding-block: 0.75rem !important;
    padding-inline: 2rem !important;
    font-size: 0.75rem !important;
  }

  .featured .button {
    padding-inline: 3rem !important;
  }
}

@media screen and (min-width: 640px) {
  .featureCard {
    padding: 1.5rem 1.5rem;
  }

  .featureCardContent {
    max-width: 80%;
  }

  .featured .featureCardContent {
    max-width: 70%;
  }

  .featureCardTitle {
    font-size: 1.75rem;
  }

  .featureCardDescription {
    font-size: 1rem;
  }

  .button {
    padding-block: 1rem !important;
    padding-inline: 2.5rem !important;
    font-size: 0.875rem !important;
  }

  .featured .button {
    padding-inline: 4rem !important;
  }
}

@media screen and (min-width: 768px) {
  .featureCard {
    padding: 1.75rem 2.25rem;
  }

  .featureCardContent {
    max-width: 80%;
  }

  .featured .featureCardContent {
    max-width: 80%;
  }

  .featureCardTitle {
    font-size: 1.75rem !important;
  } 

  .featureCardDescription {
    font-size: 1rem !important;
  }

  .button {
    padding-block: 1rem !important;
    padding-inline: 3rem !important;
    font-size: 0.875rem !important;
  }

  .featured .button {
    padding-inline: 4.5rem !important;
  }
}