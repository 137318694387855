body {
    margin: 0;
    font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll; 
    overflow-x: hidden;
  }
  
  .MuiTypography-root {
    font-family: 'Outfit' !important;
  }

  @font-face {
    font-family: 'Causten-700';
    src: url('./assests/fonts/Causten-Bold.woff2') format('woff2'),
        url('./assests/fonts/Causten-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Causten-500';
    src: url('./assests/fonts/Causten-Medium.woff2') format('woff2'),
        url('./assests/fonts/Causten-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Causten-600';
    src: url('./assests/fonts/Causten-SemiBold.woff2') format('woff2'),
        url('./assests/fonts/Causten-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Causten-400';
    src: url('./assests/fonts/Causten-Regular.woff2') format('woff2'),
        url('./assests/fonts/Causten-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

