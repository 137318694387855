.footer-section {
  background-color: #0d0d0d;
}

.footer-para {
  font-family: "Outfit";
  color: #8e8e8e;
  font-size: 1.37rem;
  line-height: 2rem;
  padding-top: 2rem;
}

.copyright-text {
  color: #adadad;
  line-height: 150%;
  font-size: 1.37rem;
}
.logo-box{
  display: flex;
  align-items: center;
  color: #848484;
  margin-block: 1rem;
  
}
.icon-box{
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  background-color:#44434F;
  border-radius: 8px;
  justify-content: center;
  margin-right: 24px;
}
.icon-box:hover img{
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
  filter: invert(30%) grayscale(100%) brightness(40%) contrast(4);}
.icon-box:hover{
  background-image:linear-gradient(265deg, #FF8181 47.38%, #FFA96B 100%);
}
.align {
  vertical-align: middle;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offer-text {
  font-family: "Outfit";
  font-size: 22px;
  vertical-align: top;
  color: #adadad;
}

.find-text,
.download-text {
  font-size: 1.37rem;
  color: #adadad;
  font-weight: 400;
  text-align: right;
}
.download-text {
  display: flex !important;
  justify-content: flex-end;
}
.download-link {
  color: #ffa96b !important;
  text-decoration: none !important;
  font-weight: 700;
  font-size: 1.37rem !important;
}
.find-text {
  /* padding-left: 14rem; */
  font-size: 1.37rem;
  color: #adadad;
  font-weight: 400;
  text-align: left;
}

.background-logo {
  position: relative;
}

.offer-text {
  font-size: 48px;
  margin-left: 9px;
  font-family: "Outfit";
}

.approved-text {
  font-weight: 700;
  color: #adadad;
  font-size: 48px;
  font-family: "Outfit";
}

.logo-alignment {
  margin-block: 1rem;
  color: #848484;
}

.gripstudioLogo {
  width: 279px;
}
.social-media-detail {
  z-index: 11;
  display: flex;
  flex-direction: row !important;
}

.footer-copyright {
  width: 100%;
  display: flex;
  padding-top: 3rem;
}

.underscore {
  color: #8e8e8e;
  text-decoration: underline;
  
}
.footer-links {
  justify-content: space-between;
}
.rest-icons {
  margin-top: 2.5rem;
}
.footer-link{
  color: #adadad !important;
  display: inline-block;
}

@media (max-width: 1024px) {
  .social-media-detail {
    z-index: 11;
    display: flex;
    flex-direction: row !important;
    display: block !important;
    text-align: center;
  }
}

@media screen and (max-width: 991.5px) {
  .footer-section {
    padding-block-start: 2rem !important;
  }
  .footer-para {
    font-size: 1rem;
    line-height: normal !important ;
    font-family: "Outfit";
  }
  .align {
    width: 30px 
    
  }
 
  .download-text {
    font-size: 1.3rem;
    text-align: right;
  }
  .copyright-text {
    font-size: 1.3rem;
  }
  .footer-links {
    justify-content: space-between;
  }
  .social-media-detail {
    z-index: 11;
    display: flex;
    flex-direction: row !important;
    display: block !important;
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer-section {
    padding-block-end: 2rem;
  }
  .page-alignment {
    padding: unset;
  }
  .footer-section-logo {
    display: none !important;
  }
  .footer-logo {
    padding-block-start: 2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }
  .align {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .gripstudioLogo {
    width: 158px;
    margin-bottom: 17px;
  }

  .download-text {
    font-size: 1rem;
    color: #adadad;
    font-weight: 400;
    padding-bottom: 2rem;
    padding-left: unset;
    justify-content: center;
  }

  .find-text {
    font-size: 1rem;
    color: #adadad;
    font-weight: 400;
    padding-top: 2rem;
    padding-left: unset;
  }

  .offer-text {
    font-size: 22px;
    margin-left: 9px;
    font-family: "Outfit";
    font-size: 16px;
  }

  .approved-text {
    font-weight: 700;
    color: #000000;
    font-size: 48px;
    font-family: "Outfit";
  }

  .footer-detail {
    min-width: 100% !important;
  }

  

  .footer-para {
    font-family: "Outfit";
    color: #8e8e8e;
    font-size: 16px;
    line-height: 20px;
    padding: 0 2rem 0 2rem;
    text-align: center;
  }

  .copyright-text {
    color: #adadad;
    padding: 2rem 0 2rem 2.5rem;
    font-size: 1rem;
  }
  .footer-copyright {
    padding-top: unset;
    display: flex;
    flex-direction: column !important;
  }
  .copyright-text {
    padding-block: 1rem;
    padding: unset;
    text-align: center;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .rest-icons {
    margin-top: 4rem;
  }
}
