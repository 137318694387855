.explore-premium-section {
  background: #0d0d0d;
  padding: 4rem;
  position: relative;
}
.premium-content {
  display: flex;
  flex-direction: row;
  margin-top: 3rem !important;
}

.bullet-point-heading {
  color: #848484;
  font-size: 1.75rem;
  margin-bottom: 12px;   
  font-family: "Causten-600";
 
}
.fore-mobile{
  display: none;
}
.fore-desktop{
  display: block;
}
.bullet-point-text {
  color: #848484;
  font-size: 1.125rem !important;
  font-family: "Causten-400";
}
.list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.link.active {
  fill: white;
  color: white;
}

.link.active .bullet-point-text {
  color: inherit;
}

.link.active .bullet-point-heading {
  color: inherit;
}

.premiumScreen1 {
  position: relative;
  z-index: 99999;
  height: auto;
  max-width: 19.12rem;
}
/* .premium-image-container {
  position: relative;
} */
.vLogo {
  position: absolute;
  z-index: 1 !important;
  right: 0;
  width: 42%;
  top: 70%;
  transform: translateY(-50%);
}

.bullet-point-section {
  margin-inline-start: 2rem;
}

.css-1bhi0za-MuiTypography-root-MuiLink-root {
  text-decoration: none !important;
}

.heading-section {
  padding: 0 2rem;
}

.premium-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .vLogo {
    position: absolute;
    z-index: 1 !important;
    right: 0;
    width: 42%;
    top: 70%;
    transform: translateY(-50%);
  }
}
@media (max-width: 991px) {
  .explore-premium-section {
    padding: unset !important;
    padding-block: 4rem !important;
  }
  .vLogo {
    top: 59%;
  }
  .premiumScreen1 {
    max-width: 15.12rem;
  }
  .bullet-point-heading {
    font-size: 1.6rem !important;
    max-width: 100%;
    margin-bottom: unset !important;
  }
  .bullet-point-text {
    font-size: 1rem !important;
  }
  .expm {
    display: flex;
    margin-left: 20px;
  }
  .bulletLogo {
    /* background-color: red; */
    width: 130%;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bulletLogo {
    min-width: 100% !important;
    height: auto;
  }
  .heading-section {
    padding: 0;
  }
  .image-container {
    margin-top: 2rem;
    margin-inline: auto;
  }
  .vLogo {
    /* display: none !important; */
    top:25%;
    width: 90%;
  }
  .list-item .link {
    fill: white;
    color: white;
  }
  .link .bullet-point-text {
    color: #848484 !important;
  }
  
  .link .bullet-point-heading {
    color: inherit;
  }
  .fore-mobile{
    display: block;
  }
  .fore-desktop{
    display: none;
  }
  
  .premium-heading {
    display: flex;
    flex-direction: column;
    color: #0000;
  }
  .premium-content {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center;
    position: relative;
  }

  .explore-premium-section {
    padding: 0 !important;
  }

  .bullet-point-heading,
  .bullet-point-text {
    text-align: center;
    margin-bottom: 0;
  }
  .bullet-point-heading {
    font-size: 1.5rem !important ;
  }
  .bullet-point-text {
    font-size: 0.9rem !important;
  }
  .expm {
    justify-content: center;
    margin: unset;
  }
  .bullet-point-section {
    padding-inline: 0rem !important;
    gap: 2rem !important;
  }
  .premiumScreen1 {
    margin-left: 2rem;
    height: auto;
  }
  .premium-image-container {
    margin-top: 2rem !important;
  }
  .image-wrapper {
    display: flex;
  }
}
