.ticket-booking-section {
  min-height: 100vh;
  background-color: #fafafa;
}

.screen4 {
  height: 31.25rem;
  width: auto;
}

.model-structure-text {
  color: #8b8b8b;
  font-weight: 400;
  font-size: 1.3rem;
}
.fore-mobile{
  display: none;
}
.fore-desktop{
  display: block;
}
.background-logo {
  position: relative;
}
.iPadApplePencil {
  width: 600px;
  height: 600px;
  position: absolute;
  left: 0;
  top: 0;
}

.tab-image {
  position: relative;
  max-width: 38rem;
}

.growing-text {
  font-family: "Causten-700";
  font-style: normal;
  font-size: 4rem;
  color: #f7f7f7;
  padding-block-end: 1rem;
  line-height: 3rem;
  line-height: 100% !important;
}

.growing-section {
  background: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 4rem 0 4rem 4rem;
}
.bullet-point-section2 {
  display: flex !important;
  flex-direction: column !important;
  gap: 2rem;
  padding-block-end: 4rem;
}

@media (min-width: 1240px) and (max-width: 1440px) {
  .tab-image {
    max-width: 56.16rem;
    right: 13.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1430px) {
  .tab-image {
    position: absolute;
    left: 60px;
    width: 51%;
  }
  .iPadApplePencil {
    width: 400px;
    height: 400px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .growing-text {
    font-size: 2rem !important;
    color: #f7f7f7;
    padding-block-end: 1rem;
    line-height: 2rem;
  }
  .growing-section {
    justify-content: space-around !important;
  }
  .bullet-heading {
    color: #848484;
    text-align: left;
    font-size: 1.5rem !important;
  }
  .bullet-para {
    color: #848484;
    text-align: left;
    font-size: 1rem !important;
  }
  .bullet-point-section2 {
    gap: 1rem;
  }
  .bullet-images {
    width: 4rem;
    height: auto;
  }
  .bullet-point {
    gap: 1rem;
  }
}
@media screen and (max-width: 1366px) {
  .growing-text {
    font-size: 4rem !important;
    color: #f7f7f7;
    padding-block-end: 1rem;
    line-height: 2rem; 
  }
}
@media screen and (max-width: 991.5px) {
  .tab-image {
    position: absolute;
    width: 20rem;
  }
  .iPadApplePencil {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .growing-text {
    font-size: 2rem !important;
    color: #f7f7f7;
    padding-block-end: 1rem;
    line-height: 2rem;
  }
  .growing-section {
    justify-content: space-around !important;
  }
  .bullet-heading {
    color: #848484;
    text-align: left;
    font-size: 1.5rem !important;
  }
  .bullet-para {
    color: #848484;
    text-align: left;
    font-size: 1rem !important;
  }
  .bullet-point-section2 {
    gap: 1rem;
  }
  .bullet-images {
    width: 3rem;
    height: auto;
  }
  .bullet-point {
    gap: 1rem;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .bullet-point-section2 {
    /* background-color: red; */
    padding-top: 15.5rem;
  }

  .iPadApplePencil,
  .tabImage {
    min-width: 100% !important;
    height: auto;
  }
  .growing-section {
    padding: unset;
  }
  .fore-mobile{
    display: block;
  }
  .fore-desktop{
    display: none;
  }
  .tab-image {
    min-width: 85% !important;
    position: absolute;
    top: 10px;
  }
  .web-view {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iPadApplePencil {
    display: none;
  }
}
