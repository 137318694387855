.baseball {
  min-height: 50vh;
  background-size: cover;
  background-position: 0% 60%;
  background-color: #000000;
  align-items: center;
}
.available-heading {
  font-size: 4rem;
  padding: 5rem 26rem 0 0rem;
}

.available-para-text {
  font-size: 1.125rem;
  color: #9c9c9c;
  font-family: "Causten-400" !important;
  padding: 3.5rem 12rem 0 0;
  font-style: normal;
}
.App-store{
  vertical-align: middle;
  justify-content: center;
}
.approved-app {
  font-size: 4rem !important;
  color: #ffffff;
  font-weight: 600;
  line-height: 100%;
  font-family: "Causten-700";
}
.playstore-icons,
.appstore-icons {
  max-width: 17.5rem;
  height: auto;
}

.android-devices-text {
  font-size: 1.17rem !important;
  color: #ffffff;
  font-weight: 700;
  padding-right: 3rem;
}

.text-section {
  margin-block: 2rem !important;
}

.android-section {
  border-left: 1px solid #fff;
  padding-left: 4rem;
  padding-top: 2rem;
  height: 100%;
}

.doenload-App-store {
  width: fit-content;
  display: flex;
  padding: 1rem;
  width: 332px;
  border-radius: 12px;
  border: 4px solid #fff;
}
.button-title {
  color: #fff;
  font-family: Outfit !important;
  font-size: 2.6rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  text-transform: capitalize !important;
}
.button-sub-title {
  color: #fff;
  font-family: Outfit !important;
  font-size: 0.8rem !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 135% !important;
  text-transform: uppercase;
}
.android-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 1366px) {
  .approved-app {
    font-size: 4rem !important;
  }
 .button-title{
  font-size: 1.7rem !important;
 }
}

@media (max-width: 1024px) {
  .page-alignment {
    padding: 2rem 4.25rem !important;
  }
  .android-section {
    border-left: 1px solid #fff;
    padding-left: 4rem;
    padding-top: 2rem;
    height: unset;
  }
  .playstore-icons,
  .appstore-icons {
    max-width: 14.5rem;
    height: auto;
  }
  .slider-class,
  .store-button {
    margin-top: 1rem;
  }
  .page-section {
    padding: 0rem;
  }
  img.playstore-image {
    margin-right: 0px;
  }
  .playstore-icons {
    max-width: 15.31rem;
    max-height: 5rem;
    margin-left: 4.5rem;
  }

  .appstore-icons {
    max-width: 15.31rem;
    max-height: 5rem;
  }

  .android-devices-text {
    font-size: 1rem !important;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    padding: 0 4rem 0 4rem;
  }
  .android-section {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-top: unset;
  }
  
  .button-title {
    font-size: 2rem !important;
    font-weight: 600 !important;
  }
  .button-sub-title {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
  }
  .doenload-App-store > img {
    width: 10% !important;
    height: auto;
  }
  .approved-app {
    font-size: 3rem !important;
    max-width: 80% !important;
  }
  .available-para-text {
    padding: unset;
    max-width: 90%;
  }
  .text-section {
    display: flex;
    gap: 2rem;
    flex-direction: column !important;
  }
  .baseball {
    min-height: unset;
  }
}
@media (max-width: 991px) {
  .approved-app {
    font-size: 2rem !important;
    padding-block-end: 2rem;
  }
  .available-para-text {
    font-size: 1rem;
    padding: unset;
    max-width: 90%;
  }
  span {
    display: block;
  }
  .page-alignment {
    padding-inline: 2rem;
  }
  .android-section {
    border-left: 1px solid #fff;
  }
  .button-title {
    font-size: 1rem !important;
  }
  .button-sub-title {
    font-size: 0.6rem !important;
  }
  .android-section {
    padding-left: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .doenload-App-store > img {
    width: 15% !important;
  }
  .baseball {
    min-height: unset;
  }
  .page-alignment {
    padding-block-start: unset;
  }
  .doenload-App-store {
    width: 140px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .approved-app {
    font-size: 2.5rem;
    font-family: "Causten-700";
    font-style: normal;
    line-height: 110%;
    text-align: center;
    padding: unset;
    min-width: 100% !important;
  }
  span {
    display: block;
  }
  .text-section {
    max-width: 100% !important;
    margin-block: 1rem !important;
  }
  .doenload-App-store {
    width: 100%;
  }
  .divider {
    display: block;
  }

  .playstore-icons {
    max-width: 15.31rem;
    max-height: 5rem;
    margin-left: 4.5rem;
  }

  .appstore-icons {
    max-width: 15.31rem;
    max-height: 5rem;
  }

  .android-devices-text {
    font-size: 1rem !important;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    padding: 0 4rem 0 4rem;
  }
  .android-section {
    display: flex;
    align-items: center;
    padding-left: 0;
    border-left: 0;
    padding-top: unset;
    height: unset;
  }

  .doenload-App-store {
    max-width: 70% !important;
  }
  .button-sub-title {
    font-size: 1rem !important;
  }
  .button-title {
    font-size: 2rem !important;
  }
  .doenload-App-store > img {
    width: unset;
  }
  .page-alignment {
    padding: 0 !important;
  }
  .available-para-text {
    font-size: 1rem !important;
    padding: unset;
    padding-block-start: 1rem;
    text-align: center !important;
    min-width: 100% !important;
  }
  .baseball {
    padding-block: 4rem !important;
  }
}
