.header-chip {
  /* margin-inline: 0; */
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.header-link {
  color: white !important;
  text-decoration: none;
  list-style: none;
  text-align: center;
}
.users-link {
  border: 1px solid #44434f !important;
  padding-inline: 13.5rem !important;
  padding-block: 0.8rem !important;
  font-size: 0.9rem !important;
  border-radius: 5px !important;
  font-family: "Causten-700" !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
}
.gym-link {
  border: 1px solid #44434f !important;
  padding-block: 0.8rem !important;
  font-size: 0.9rem !important;
  padding-inline: 8.675rem !important;
  border-radius: 5px !important;
  font-family: "Causten-700" !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
}
.download-header-button {
  list-style: none;
  text-align: center;
}
.active > button {
  outline: 1px solid #ff8181 !important;
  border-radius: 5px !important;
  background-color: #161616;
  color: #ff8181 !important;
}

.nav-icon{
  width :1.5em !important;
}
@media screen and (max-width: 767px) {
  .users-link {
    border: 1px solid #44434f !important;
    padding-inline: 6rem !important;
    padding-block: 0.8rem !important;
    font-size: 0.9rem !important;
    background: #44434f;
    border-radius: 5px !important;
    font-family: "Causten-700" !important;
    text-transform: capitalize !important;
  }
  .gym-link {
    border: 1px solid #44434f !important;
    padding-block: 0.8rem !important;
    font-size: 0.9rem !important;
    padding-inline: 1.1rem !important;
    background: #44434f;
    border-radius: 5px !important;
    font-family: "Causten-700" !important;
    text-transform: capitalize !important;
  }
  .active > button {
    outline: 1px solid #ff8181 !important;
    border-radius: 5px !important;
    background-color: #161616;
    color: #ff8181 !important;
  }

}
