.logo-link{
      padding: 0.5rem 0.5rem;
}
.logo-link > img{
      width: 250px;
      height: auto;
      cursor: pointer;
     
}
@media screen and (max-width: 768px) {
      .logo-link > img{
            width: 290px;
      } 
}
.appbar{
      box-shadow: unset !important;
      background-color: #fff !important;
}