.header-section {
  background-color: #0c0c0c;
  box-sizing: border-box;
}

.gripstudioLogo-header {
  width: 13rem;
}
.page-section {
  padding: 4rem 2rem;
}

.slider-heading {
  font-family: "Causten-700";
  font-weight: 700;
  text-align: center;
  line-height: 100%;
}

.header-tag {
  font-family: "Causten-700";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 140%;
  color: #f7f7f7;
  list-style-type: none;
  margin-left: 1.5rem;
}

.header-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.swiper-pagination,
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}
.title-text {
  color: #ffffff;
}

.swiper {
  padding-top: 4rem !important;
}

.vertical-box {
  border: 2px solid #44434f;
  width: 12.5rem !important;
  height: 12.5rem !important;
  text-align: center;
  border-radius: 20px;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.title-text {
  font-family: "Causten-600";
  font-style: normal;
  font-weight: 600;
  font-size: 29.2683px;
  line-height: 100%;
}

.box-class img {
  display: block;
  margin: 0 auto 30px;
}

.playstore-image {
  max-width: 18.62rem;
  max-height: 4.5rem;
}

.mobile-image {
  position: relative;
  max-width: 320px;
  z-index: 11;
  display: flex;
  justify-content: center;
}

.mobile-image img {
  max-width: 100%;
}

.slider-class {
  position: relative;
  background-color: #0c0c0c;
  margin-top: 5rem;
}
.swiper-slide {
  width: 211px !important;
}

.mySwiper {
  position: absolute !important;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header {
  align-items: center;
  padding: 0 5%;
}

.header-list {
  list-style-type: none;
  padding: 0;
}
.store-button {
  margin-top: 3rem;
}
.header-tag {
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-size: 1rem !important;
  font-family: "Causten-700" !important;
  border-radius: 2px !important;
  background-color: #161616;
  border: 1px solid #44434f !important;
  padding: 0.5rem 50px;
}
.header-tag.active {
  border: 1px solid #ff8181 !important;
  color: #ff8181;
}

@media screen and (min-width: 1440px) {
  .swiper-slide {
    width: 211px !important;
  }

  .store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
  }

  .app-button {
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1.5rem 1rem 1.5rem 3.6rem;
    font-size: 1.2rem;
    font-weight: 400;
    background-color: #1f1f1f;
    color: white;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid #44434f;
    text-align: center;
    width: 380px;
}

  .app-button::before {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 15px;
    width: 10%;
    height: 40px;
  }

  .app-button:nth-of-type(1)::before {
    background-image: url("../../assests/google-play.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app-button:nth-of-type(2)::before {
    background-image: url("../../assests/apple.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
/* Media query for iPad Pro */
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .vertical-box {
    margin-inline: 1rem !important;
    display: flex !important;
    width: 10rem !important;
  }
  .swiper-slide {
    width: 180px !important;
  }
  .box-class {
    padding-inline: 1rem;
    font-size: 1rem;
  }
  .header-tag {
    font-size: 1.25rem;
    margin-left: 1rem;
  }
  .download-button {
    padding: 0.8rem 2rem !important;
  }
  .store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    gap: 1rem;
  }

  .app-button {
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem 5rem;
    font-size: 0.8rem;
    font-weight: 400;
    background-color: #1f1f1f;
    color: white;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid #44434f;
    text-align: center;
  }

  .app-button::before {
    content: "";
    position: absolute;
    left: 40px;
    bottom: 9px;
    width: 10%;
    height: 30px;
  }

  .app-button:nth-of-type(1)::before {
    background-image: url("../../assests/google-play.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app-button:nth-of-type(2)::before {
    background-image: url("../../assests/apple.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slider-class {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .vertical-box {
    border: 2px solid #44434f;
    width: 9rem !important;
    height: 10rem !important;
    font-size: 0.8rem;
  }
  .swiper-slide {
    width: 160px !important;
  }

  .header-tag {
    margin: 0;
  }
  .header-tag:hover {
    color: #ff8181;
  }
  .header-tag.active::after {
    display: none;
  }
  .header-list {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }

  .page-section {
    padding: 1rem 0rem;
  }

  .slider-class {
    margin-top: 3rem;
  }
  .download-button {
    padding: 0.8rem 11.675rem !important;
    margin-top: 2rem !important;
  }
  .store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    gap: 1rem;
  }

  .app-button {
    justify-content: center;
    align-items: center;

    position: relative;
    padding: 1rem 8rem;
    font-size: 1.2rem;
    font-weight: 400;
    background-color: #1f1f1f;
    color: white;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid #44434f;
    text-align: center;
  }

  .app-button::before {
    content: "";
    position: absolute;
    left: 60px;
    bottom: 9px;
    width: 10%;
    height: 40px;
  }

  .app-button:nth-of-type(1)::before {
    background-image: url("../../assests/google-play.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app-button:nth-of-type(2)::before {
    background-image: url("../../assests/apple.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slider-class {
    margin-top: 3rem;
  }
  .header {
    justify-content: center;
  }
  .header-tag {
    margin: 0;
    font-size: 1.5rem;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
a {
  color: #ffff;
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .gripstudioLogo-header{
    padding-top: 28px;
    padding-bottom: 10px;
  }
  .logo {
    text-align: center;
  }
  .header-tag {
    margin: 0;
    font-size: 1rem;
  }
  .header-tag:hover {
    color: #ff8181;
  }
  .header-tag.active::after {
    display: none;
  }
  .header-list {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0rem;
  }

  .mySwiper {
    display: none !important;
  }
  .page-section {
    padding: 1rem 0rem;
  }

  .download-button {
    margin-top: 1rem !important;
    padding: 0.8rem 5rem !important;
  }
  .store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    gap: 1rem;
  }

  .app-button {
    justify-content: center;
    align-items: center;

    position: relative;
    padding: 1rem 5rem;
    font-size: 0.8rem;
    font-weight: 400;
    background-color: #1f1f1f;
    color: white;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid #44434f;
    text-align: center;
  }

  .app-button::before {
    content: "";
    position: absolute;
    left: 40px;
    bottom: 9px;
    width: 10%;
    height: 30px;
  }

  .app-button:nth-of-type(1)::before {
    background-image: url("../../assests/google-play.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app-button:nth-of-type(2)::before {
    background-image: url("../../assests/apple.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slider-class {
    margin-top: 3rem;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .slider-heading {
    padding: 0px;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
  }
}