.genricHeading-text {
  font-size: 4rem;
  color: #f7f7f7;
  text-align: center;
}

.genricHeading-para {
  font-size: 1.125rem;
  color: #848484;
  text-align: center;
  padding: 0 4.5rem;
}

.generic-heading {
  padding: 2rem 0;
  display: grid !important;
}

.generic-flex {
  justify-content: center;
}
@media screen and (max-width: 1366px) {
  .genricHeading-text {
    font-size: 4rem;
  }
}
@media screen and (max-width: 1024px) {
  .genricHeading-text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 991.5px) {
  .genricHeading-text {
    font-size: 2rem !important;
  }

  .genricHeading-para {
    font-size: 1.1rem;
  }
  .generic-heading {
    padding-bottom: 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .genricHeading-text {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 110%;
    text-align: center;
  }

  .genricHeading-para {
    font-size: 1.1rem;
    line-height: 150%;
    padding: unset;
    padding-block-start: 1rem;
    max-width: 90%;
    margin-inline: auto !important;
    text-align: center !important;
  }
  .generic-heading {
    padding-bottom: 0;
  }
}
