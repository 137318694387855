.bullet-point {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.bullet-container {
  display: flex;
  text-align: left;
  flex-direction: column !important;
  justify-content: flex-start;
  cursor: pointer;
}
.bullet-heading {
  color: #848484;
  text-align: left;
  font-size: 1.75rem !important;
  font-family: "Causten-600" !important;
}
.bullet-para {
  color: #848484 !important;
  text-align: left;
  font-size: 1.125rem !important;
  font-family: "Causten-400" !important;


}
.selected {
  color: #fafafa !important;
  cursor: pointer;
}
@media (max-width: 1366px) {
  .growing-text {
    font-size: 4rem !important;
  }
  .bullet-container {
    max-width: 80% !important;
  }
}
@media (max-width: 1024px) {
  .bullet-container {
    display: flex;
    text-align: left;
    flex-direction: column !important;
    justify-content: flex-start;
    cursor: pointer;
    max-width: 80% !important;
  }
  .bullet-point {
    gap: unset;
  }
  .selected {
    color: #fafafa;
    cursor: pointer;
  }
  .growing-text {
    font-size: 3rem !important;
  }
}

@media (max-width: 991px) {
  .bullet-container {
    max-width: 80%;
  }
  .selected {
    color: #fafafa;
    cursor: pointer;
  }
  .growing-text {
    font-size: 2rem !important;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .bullet-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .growing-text {
    text-align: center;
    font-size: 2rem !important;
    line-height: 2rem !important;
    padding-block-start: 2rem;
    padding-inline: 1rem;
  }
  .bullet-heading {
    text-align: center;
    color: #fafafa;
  }
  .bullet-para {
    text-align: center;
    padding-inline: 0;
  }
  .selected {
    color: #fafafa;
    cursor: pointer;
  }
  .bullet-point {
    flex-direction: column !important;
    gap: 0;
  }
  .bullet-images {
    width: 90% !important ;
  }
}
